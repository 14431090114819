/* ======================
Main blog page
========================= */

.blog-card * {
  text-align: left;
}

.blog-card h3 {
  color: var(--clr-dark);
  font-size: 1.4em;
}

.blog-card.dark-theme h3 {
  color: var(--clr-tertiary);
  background-color: inherit;
}

.blog-card p {
  color: var(--clr-very-dark);
}

.blog-card {
  padding: 0 2em;
  margin: 3em;
  display: flex;
  flex-direction: row;
  align-items: left;
  transform: scale(1);
  transition: transform 250ms;
}

.blog-card-img {
  min-width: 275px;
  width: 275px;
  height: 150px;
  object-fit: cover;
  padding-right: 2em;
}

.blog-card:hover,
.blog-card:focus {
  transform: scale(1.03);
}

.blog-card-link {
  text-decoration: none;
}

.blog-card-link:hover,
.blog-card-link:focus {
  text-decoration: none;
}

.blog-card-title {
  padding: 0;
  margin: 0;
}

.blog-card-exerpt {
  padding: 0.5em 0;
}

.blog-card-date,
.blog-date {
  text-transform: uppercase;
  font-weight: 500;
  font-size: 0.9em;
  opacity: 0.6;
}

/* Remove margin-top on first BlogCard and margin-bottom on last BlogCard */
.blog-cards-container {
  max-width: 1000px;
  margin: -3em auto;
  /* background: palegreen; */
}

@media (max-width: 810px) {
  .blog-card {
    flex-direction: column;
  }

  .blog-card-img {
    padding-right: 0;
    width: 100%;
    height: calc(100vw * 0.4);
  }

  .blog-card-title {
    margin-top: 1em;
  }
}

/* ======================
Individual blog pages
========================= */

.blog-container {
  margin: 0 auto;
  padding: 0 4em;
  max-width: 1000px;
  text-align: left;
}

.blog-img {
  object-fit: cover;
  width: 100%;
  margin-bottom: 2em;
}

.blog-date {
  margin: 1.2em 0;
}

.blog-content {
  margin-top: 1.6em;
}

.blog-content li {
  font-size: 1em;
  font-weight: 400;
  margin: 0.8em 0;
}

.blog-content h3 {
  margin: 1.2em 0;
  line-height: 1.5em;
}

.within-article-link {
  color: var(--clr-dark);
}

.within-article-link.light-theme {
  color: var(--clr-tertiary);
}

.within-article-link:hover,
.within-article-link:focus {
  color: var(--clr-primary);
  text-decoration: none;
}

.blog-content.dark-theme a:link,
.blog-content.dark-theme a:visited {
  color: var(--clr-tertiary);
}
