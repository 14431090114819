.cmn-toggle {
    position: absolute;
    margin-left: -9999px;
    visibility: hidden;
}

.cmn-toggle + label {
    display: block;
    position: relative;
    cursor: pointer;
    outline: none;
    user-select: none;
}

input.cmn-toggle-round-flat + label {
    padding: 2px;
    width: 60px;
    height: 30px;
    background-color: var(--clr-dark);
    border-radius: 30px;
    transition: background 0.4s;
}

input.cmn-toggle-round-flat + label:before,
input.cmn-toggle-round-flat + label:after {
    display: block;
    position: absolute;
    content: "";
}

input.cmn-toggle-round-flat + label:before {
    top: 2px;
    left: 2px;
    bottom: 2px;
    right: 2px;
    background-color: #fff;
    border-radius: 30px;
    transition: background 0.4s;
}

input.cmn-toggle-round-flat + label:after {
    top: 4px;
    left: 4px;
    bottom: 4px;
    width: 22px;
    background-color: var(--clr-dark);
    border-radius: 22px;
    transition: margin 0.4s, background 0.4s;
}

input.cmn-toggle-round-flat:checked + label {
    background-color: var(--clr-dark);
}

input.cmn-toggle-round-flat:checked + label:after {
    margin-left: 30px;
    background-color: var(--clr-dark);
}

.dark-theme input.cmn-toggle-round-flat + label:before {
    background-color: var(--clr-very-dark);
}

.dark-theme input.cmn-toggle-round-flat + label,
.dark-theme input.cmn-toggle-round-flat + label:after {
    background-color: var(--clr-tertiary);    
}
