/* ======================
Recipe pages
========================= */

h3 {
    font-weight: 700;
}

.main-content-container {
  text-align: center;
}

.recipe-cards-container {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  max-width: 1000px;
  margin: 0 auto;
}

.recipe-card {
  background: var(--clr-primary);
  width: 25%;
  margin: 1em;
  padding: 1.2em 0.7em;
}

.recipe-card-title {
  color: white;
  text-transform: uppercase;
  font-size: 1.6em;
  font-weight: 400;
}

.view-more-link {
    color: var(--clr-accent);
}

.view-more-link:hover,
.view-more-link:focus {
    text-decoration: none;
    color: var(--clr-black);
}

.main-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 90%;
    margin: 0 auto;
}

.recipe-content-container {
    max-width: 1000px;
    margin: 0 auto;
}

.recipe-main p, li, 
.recipe-metric {
    font-weight: 300;
    font-size: 1.1em;
}

.recipe-desc {
    font-weight: 300;
    font-size: 1.3em;
}

.recipe-quick-summary {
    margin: 2em;
    padding: 0;
}

.recipe-quick-summary p {
    margin: 0;
}

.recipe-measure {
    width: 70px;
    font-weight: 700;
}

.recipe-metric {
    display: flex;
    flex-direction: row;
    align-items: baseline;
}

/*image on the actual recipe page*/
.recipe-img {
    max-height: 300px;
    height: 100%;
    object-fit: cover;
    margin-left: 1em;
}

.recipe-desc {
    margin: 1em;
    width: 80%;
    font-style: italic;
    font-size: 1.2em;
}

.recipe-container {
    display: flex;   
    flex-direction: column;
    align-items: left;
    margin: 0 2em;
}

.recipe-container h3, 
.recipe-measure {
    text-transform: uppercase;
    font-size: 1.25rem;
    font-family: var(--ff-primary);
    letter-spacing: 0.04em;
}

.instructions-container li {
    margin: 0.6em 0;
}

.ingredients-container li {
    margin: 0.2em 0;
}

.instructions-container,
.ingredients-container,
.recipe-desc {
    text-align: left;
    line-height: 1.35em;
}

.recipe-subtitle {
    margin: 1em 0;
}

/* Layout for larger screen sizes */ 
@media (min-width: 600px) {

    .recipe-container {
        margin: 1em 2em;
        flex-direction: row;
        justify-content: space-between;
        justify-content: space-evenly;
        align-items: flex-start;
    }

    .recipe-img, 
    .recipe-desc,
    .instructions-container {
        width: 50%;
    }

    .recipe-main {
        margin-top: 2em;       
    }    

    .recipe-desc {
        margin: 0 1em;
    }

    .recipe-img {
        margin: 0;
    }

    .recipe-subtitle {
        margin: 0 0 1em 0;
    }
}