:root {
  --clr-very-dark: #303030;
  --clr-very-dark-light: #3a3b3a;
  --clr-dark: #5801cb;
  --clr-light-dark: #6900f3;
  --clr-primary: #a36fe3;
  --clr-second-primary: #d18fff;
  --clr-secondary: plum;
  --clr-tertiary: #f2d3ed;
  --clr-light: white;
  --clr-accent: #f5df38;

  --ff-primary: "Roboto", sans-serif;
  --ff-secondary: "Cairo", sans-serif;
  --ff-fancy: "Dancing Script", cursive;
}

/* ======================
Custom properties
========================= */

:root {
  --fw-reg: 400;
  --fw-bold: 700;

  --fs-h1: 2.3rem;
  --fs-h2: 1.8rem;
  --fs-h3: 1.1rem;
  --fs-h4: 1rem;
  --fs-body: 1rem;

  --bs: 0.25em 0.25em 0.75em rgba(0, 0, 0, 0.25),
    0.125em 0.125em 0.25em rgba(0, 0, 0, 0.15);
}

@media (min-width: 800px) {
  :root {
    --fs-h1: 2.8rem;
    --fs-h2: 2rem;
    --fs-h3: 1.4rem;
    --fs-h4: 1.2rem;
    --fs-body: 1.05rem;
  }
}

/* ======================
Dark vs. light theme
========================= */

.dark-theme,
.main-content-container.dark-theme {
  background-color: var(--clr-very-dark-light);
}

.dark-theme,
.main-content-container.dark-theme h1,
.main-content-container.dark-theme h3,
.main-content-container.dark-theme p {
  color: var(--clr-light);
}

nav.dark-theme,
footer.dark-theme,
.navbar-links.dark-theme {
  background-color: var(--clr-very-dark);
}

nav.dark-theme,
footer.dark-theme,
.navbar-links.dark-theme * {
  color: var(--clr-tertiary);
}

.light-theme {
  background-color: var(--clr-light);
  color: var(--clr-very-dark);
}

footer.light-theme {
  color: var(--clr-dark);
}

/* ======================
General Styles
========================= */

h1,
h2 {
  font-family: var(--ff-secondary);
  color: var(--clr-dark);
  font-weight: 600;
  line-height: 1.5;
}

h1 {
  font-weight: 700;
  padding: 0.5em 0;
}

.main-content-container {
  padding: 6em 0;
}

select.form-control,
textarea.form-control,
input.form-control {
  font-size: 1rem;
}

input[type="file"] {
  width: 100%;
}

.App {
  margin: 0;
  padding: 0;
  color: var(--clr-very-dark);
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--ff-primary);
}

.logo-link {
  transform: scale(1);
  transition: transform 250ms;
}

.logo-link:hover,
.logo-link:focus {
  text-decoration: none;
  transform: scale(1.1);
}

.logo-title {
  color: var(--clr-dark);
  font-size: 1.1em;
  font-weight: 500;
}

.App-logo {
  height: 30px;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.App-navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.5em;
}

.navbar-link {
  text-decoration: none;
  margin: 0 1em;
  text-transform: uppercase;
  font-size: 1.1em;
  color: var(--clr-dark);
  font-family: var(--ff-secondary);
  font-weight: 600;
}

.navbar-link:hover,
.navbar-link:focus {
  color: var(--clr-primary);
  border-bottom: 2px solid var(--clr-primary);
  text-decoration: none;
}

.navbar-links {
  height: 35px;
  display: flex;
  flex-direction: row;
}

/* ======================
Small screens
========================= */
@media (max-width: 650px) {
  .main-content-container {
    padding: 3em 0;
  }

  .App-navbar {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .App-logo {
    margin-bottom: 1em;
  }

  .navbar-links {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
  }

  .navbar-link:hover,
  .navbar-link:focus {
    border-bottom: none;
  }

  .cmn-toggle + label {
    margin: 0.5em auto;
  }
}

/* ======================
Footer
========================= */

footer {
  display: block;
  background: var(--clr-very-dark);
  color: var(--clr-tertiary);
  text-align: center;
}

footer a {
  color: inherit;
  text-decoration: none;
}

.copyright {
  margin: 0;
  font-family: var(--ff-secondary);
  letter-spacing: 0.03em;
  font-size: 1.2em;
  padding-bottom: 1.5em;
  opacity: 0.6;
}

.social-list {
  padding: 1.2em 0;
  margin: 0;
  list-style: none;
  display: flex;
  justify-content: center;
  font-size: 1.7em;
}

.social-list-item {
  margin: 0 1.2em;
}

.social-list-link:hover {
  color: var(--clr-primary);
}
